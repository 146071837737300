






























































































































































































import { Component, Vue } from "vue-property-decorator";
import ModellerInputs from "@/components/ModellerInputs.vue";
import TermsWrapper from "@/components/TermsWrapper.vue";
import Assumptions from "@/components/Assumptions.vue";
import {Engine, CalcOutput, UserInputs} from "@/calculations/engine";
import InfoIcon from '@/components/InfoIcon.vue';
import moment from 'moment';
// import axios from 'axios';

@Component({
  components: {
    ModellerInputs,
    InfoIcon,
    TermsWrapper,
    Assumptions
  },
})
export default class Home extends Vue {
  public currentView = 'intro';
  public results: CalcOutput = null;
  public caseNumberValue = '';
  public showModellerNav = false;
  public validResults = false;
  public validCaseNumber = true;
  public noCaseNumber = true;

  public viewInputs(): void {
    this.viewInputsAsync();
  }

  public viewInputsAsync(): void {
    //check casenumber
    if (this.caseNumberValue !== '') {

      const check = true;
      var caseNumberLowered: string = this.caseNumberValue.toLowerCase();
      const left4 = caseNumberLowered.substring(0, 4);
      const caseVal = parseInt(caseNumberLowered.substring(4,10));

      if(left4 === 'l000' && caseVal > 105961 && caseVal < 473432) {
        this.validCaseNumber = true;
        this.noCaseNumber = true;
          if (this.isTCAgreed()) {
            this.currentView = "modeller-inputs";
            this.showModellerNav = true;
          } else {
            this.currentView = "terms";
            this.showModellerNav = false;
          }
      }else{
        this.validCaseNumber = false;
        this.noCaseNumber = true;
      }

    } else {
      this.noCaseNumber = false;
    }
    return;
  }

  public showResults(): void {
    if (this.isValidated) {
      this.results = Engine(this.inputResults);
      this.validResults = true;
    }
  }

   public hideResults(): void {
    this.validResults = false;
  }

  public setNav(nav: string): void {
    this.currentView = nav;
  }

  isTCAgreed() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const localTermsAgreed = localStorage.getItem('LeighdayTerms');
    let tcValid = false;
    if (localTermsAgreed) {
      if (parseInt(localTermsAgreed) > moment().unix()) {
        tcValid = true;
      }
    }
    return tcValid;
  }

  acceptTerms() {
    const agreementTimestamp: number = moment()
      .add(1, 'month')
      .unix();
    window.localStorage.setItem('LeighdayTerms', agreementTimestamp.toString());
    this.currentView = "modeller-inputs";
    this.showModellerNav = true;
  }

  get isValidated(): boolean {
    return this.$store.state.isValidated;
  }
  get inputResults(): UserInputs {
    return this.$store.state.inputValues;
  }

  get currentViewClass() {
    let tabclass;
    if (this.currentView === 'intro') tabclass = 'background';
    if (this.currentView === 'modeller-inputs') tabclass = 'modeller';
    if (this.currentView === 'assumptions') tabclass = 'assumptions-wrapper';
    if (this.currentView === 'terms') tabclass = 'terms-wrapper';

    return tabclass;
  }
}
