import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inputValues: {
      status: "",
      dob: "",
      gender: "",
      dateJoinedService: "",
      legacyScheme: "",
      currentSalary: "",
      currentRank: "",
      dateRankAchieved: "",
      dateOfLeaving: "",
    },
    isValidated: false,
  },
  mutations: {
    updateInputValues(state, payload) {
      Vue.set(state, "inputValues", payload);
    },
    udateIsValidated(state, validated: boolean) {
      Vue.set(state, "isValidated", validated);
    },
  },
});
