





















































































import { Component, Vue } from 'vue-property-decorator';
@Component
export default class Assumptions extends Vue {
  data() {
    return {};
  }
}
