











import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class InfoIcon extends Vue {
  @Prop() content!: string;
  @Prop() iconNumber!: number;

  public currentIcon: null | number = null;
}
