var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modeller-inputs"},[_c('div',{staticClass:"questions"},[_c('p',[_vm._v(" Please answer all the questions below and then click 'View results' to see your results ")]),_c('div',{staticClass:"display-col-2",on:{"click":_vm.setDirty}},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon"},[_c('InfoIcon',{attrs:{"content":"If you have left active service and have not yet retired you are classed as a 'Deferred Pensioner'"}})],1),_c('label',[_vm._v("Current scheme status")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.status),expression:"inputValues.status"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputValues, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Active"}},[_vm._v("Active")]),_c('option',{attrs:{"value":"Deferred Pensioner"}},[_vm._v("Deferred Pensioner")]),_c('option',{attrs:{"value":"Pensioner"}},[_vm._v("Pensioner")])]),(!_vm.$v.inputValues.status.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please select a current scheme status ")]):_vm._e()]),(
          _vm.inputValues.status == 'Deferred Pensioner' ||
          _vm.inputValues.status == 'Pensioner'
        )?_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon"},[_c('InfoIcon',{attrs:{"content":"Please use the format dd/mm/yyyy"}})],1),(_vm.inputValues.status == 'Deferred Pensioner')?_c('label',[_vm._v("Date you left service")]):_vm._e(),(_vm.inputValues.status == 'Pensioner')?_c('label',[_vm._v("Date you retired")]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.dateOfLeaving),expression:"inputValues.dateOfLeaving"}],attrs:{"type":"text","placeholder":"E.g. 12/01/2017"},domProps:{"value":(_vm.inputValues.dateOfLeaving)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputValues, "dateOfLeaving", $event.target.value)}}}),(!_vm.$v.inputValues.dateOfLeaving.required &&
            _vm.$v.$dirty && _vm.inputValues.status == 'Deferred Pensioner'
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please add a date of leaving service ")]):_vm._e(),(!_vm.$v.inputValues.dateOfLeaving.required &&
            _vm.$v.$dirty && _vm.inputValues.status == 'Pensioner'
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please add a date of retirement ")]):_vm._e(),(_vm.$v.inputValues.dateOfLeaving.required && !_vm.$v.inputValues.dateOfLeaving.isNeededAndDate &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please enter a valid date ")]):_vm._e(),(
            _vm.$v.inputValues.dateOfLeaving.isNeededAndDate &&
            _vm.$v.inputValues.dateOfLeaving.required &&
            !_vm.$v.inputValues.dateOfLeaving.isValidRange &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please check your date ")]):_vm._e()]):_c('div',{staticClass:"input-wrapper"})]),_c('div',{staticClass:"display-col-2",on:{"click":_vm.setDirty}},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon"},[_c('InfoIcon',{attrs:{"content":"This is required for the calculation of your state pension age."}})],1),_c('label',[_vm._v("Sex")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.gender),expression:"inputValues.gender"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputValues, "gender", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}}),_c('option',{attrs:{"value":"Male"}},[_vm._v("Male")]),_c('option',{attrs:{"value":"Female"}},[_vm._v("Female")])]),(!_vm.$v.inputValues.gender.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please select a gender ")]):_vm._e()]),_c('div',{staticClass:"input-wrapper mobile-margin-fix"},[_c('div',{staticClass:"info-icon"},[_c('InfoIcon',{attrs:{"content":"Please use the format dd/mm/yyyy"}})],1),_c('label',[_vm._v("Date of birth")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.dob),expression:"inputValues.dob"}],attrs:{"type":"text","placeholder":"E.g. 12/01/1980"},domProps:{"value":(_vm.inputValues.dob)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputValues, "dob", $event.target.value)}}}),(!_vm.$v.inputValues.dob.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Date of birth is required ")]):_vm._e(),(
            !_vm.$v.inputValues.dob.isDate &&
            _vm.$v.inputValues.dob.required &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please enter a valid date ")]):_vm._e(),(
            _vm.$v.inputValues.dob.isDate &&
            _vm.$v.inputValues.dob.required &&
            !_vm.$v.inputValues.dob.isValidRange &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please check your dob ")]):_vm._e()]),_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon position-fix"},[_c('InfoIcon',{attrs:{"content":"Please enter an annual full time pensionable salary. Pensionable salary includes basic salary, London weighting, additional salary on temporary promotion and competence related threshold payments. Overtime pay, housing allowance and transitional rent allowance should not be included. This information is used to estimate your final salary at retirement and also your historic CARE salaries.  If you are not an active member of the scheme, this should be your salary when you left."}})],1),(
            _vm.inputValues.status == 'Deferred Pensioner'
          )?_c('label',[_vm._v("Salary when you left")]):(
            _vm.inputValues.status == 'Pensioner'
          )?_c('label',[_vm._v("Salary when you retired")]):_c('label',[_vm._v("Current salary")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.currentSalary),expression:"inputValues.currentSalary"}],attrs:{"type":"text"},domProps:{"value":(_vm.inputValues.currentSalary)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputValues, "currentSalary", $event.target.value)}}}),(!_vm.$v.inputValues.currentSalary.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please enter an annual salary ")]):_vm._e(),(
            !_vm.$v.inputValues.currentSalary.between &&
            _vm.$v.inputValues.currentSalary.required &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Your annual salary should be more than £7,500 ")]):_vm._e()])]),_c('div',{staticClass:"display-col-2",on:{"click":_vm.setDirty}},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon"},[_c('InfoIcon',{attrs:{"content":"Please use the format dd/mm/yyyy. This information is used to work out your retirement age for the legacy schemes and should reflect any transferred in service, e.g. be 1 year prior to your actual start date if you transferred in 1 year of service."}})],1),_c('label',[_vm._v("Date you joined the service")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.dateJoinedService),expression:"inputValues.dateJoinedService"}],attrs:{"type":"text","placeholder":"E.g. 12/01/2000"},domProps:{"value":(_vm.inputValues.dateJoinedService)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.inputValues, "dateJoinedService", $event.target.value)}}}),(!_vm.$v.inputValues.dateJoinedService.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please add a date joined service ")]):_vm._e(),(
            !_vm.$v.inputValues.dateJoinedService.isDate &&
            _vm.$v.inputValues.dateJoinedService.required &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please enter a valid date ")]):_vm._e(),(
            _vm.$v.inputValues.dateJoinedService.isDate &&
            _vm.$v.inputValues.dateJoinedService.required &&
            !_vm.$v.inputValues.dateJoinedService.isValidRange &&
            _vm.$v.$dirty
          )?_c('div',{staticClass:"form-error"},[_vm._v(" Please check your date ")]):_vm._e()]),_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon position-fix"},[_c('InfoIcon',{attrs:{"content":"This information is used to calculate the value of your legacy benefits, which will be different depending on if you were in the 1987 or the 2006 scheme."}})],1),_c('label',[_vm._v("Legacy scheme")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.legacyScheme),expression:"inputValues.legacyScheme"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputValues, "legacyScheme", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select a scheme")]),_c('option',{attrs:{"value":"2006"}},[_vm._v("2006")]),_c('option',{attrs:{"value":"1987"}},[_vm._v("1987")])]),(!_vm.$v.inputValues.legacyScheme.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please Select a scheme ")]):_vm._e()])]),_c('div',{staticClass:"display-col-2",on:{"click":_vm.setDirty}},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon "},[_c('InfoIcon',{attrs:{"content":"If you were a member of the 1987 scheme, the age at which you could retire is different depending on your rank (at retirement).  If you're not sure please select 'other'."}})],1),(
            _vm.inputValues.status == 'Deferred Pensioner'
          )?_c('label',[_vm._v("Rank when you left service")]):(
            _vm.inputValues.status == 'Pensioner'
          )?_c('label',[_vm._v("Rank when you retired")]):_c('label',[_vm._v("Current rank")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.currentRank),expression:"inputValues.currentRank"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputValues, "currentRank", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select a rank")]),_c('option',{attrs:{"value":"Constable / Sergeant"}},[_vm._v("Constable / Sergeant")]),_c('option',{attrs:{"value":"Inspector / Chief Supt"}},[_vm._v(" Inspector / Chief Supt ")]),_c('option',{attrs:{"value":"Commander / Dep Asst Commissioner"}},[_vm._v(" Commander / Dep Asst Commissioner ")]),_c('option',{attrs:{"value":"Other"}},[_vm._v("Other")])]),(!_vm.$v.inputValues.currentRank.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please Select a rank ")]):_vm._e()]),_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"info-icon position-fix"},[_c('InfoIcon',{attrs:{"content":"This information is used to help estimate your final salary at retirement and also your historic CARE salaries, based on current salary scales."}})],1),(
            _vm.inputValues.status == 'Deferred Pensioner'
          )?_c('label',[_vm._v("Date rank was achieved before leaving")]):(
            _vm.inputValues.status == 'Pensioner'
          )?_c('label',[_vm._v("Date rank was achieved before retiring")]):_c('label',[_vm._v("Date rank was achieved")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputValues.dateRankAchieved),expression:"inputValues.dateRankAchieved"}],on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.inputValues, "dateRankAchieved", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Please select a time period")]),_c('option',{attrs:{"value":"Less than 1 year ago"}},[_vm._v("Less than 1 year ago")]),_c('option',{attrs:{"value":"1 to 5 years ago"}},[_vm._v("1 to 5 years ago")]),_c('option',{attrs:{"value":"More than 5 years"}},[_vm._v("More than 5 years ago")])]),(!_vm.$v.inputValues.dateRankAchieved.required && _vm.$v.$dirty)?_c('div',{staticClass:"form-error"},[_vm._v(" Please select a time period ")]):_vm._e()])]),_c('div',{staticClass:"center-block"},[_c('div',{staticClass:"btn-green-dark",on:{"click":_vm.showResults}},[_vm._v("View results")])]),_vm._v(" ` ")])])}
var staticRenderFns = []

export { render, staticRenderFns }