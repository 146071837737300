























































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Validations } from 'vuelidate-property-decorators';
import { required, between, requiredIf } from 'vuelidate/lib/validators';
import { UserInputs } from '@/calculations/engine';
import moment from 'moment';
import InfoIcon from '@/components/InfoIcon.vue';

const isDate = (val) => {
  //if (val.length !== 10) return false;
  const dateval = moment(val, 'D/M/YYYY', true);
  return dateval.isValid();
};

const replacePoounds = (val) => {
  return val.replace('£', '').replace(',', '');
}

@Component({
  components: {
    InfoIcon,
  },
})
export default class ModellerInputs extends Vue {
  public inputValues = {
    status: 'Active',
    dob: '05/09/1969',
    gender: 'Male',
    dateJoinedService: '07/09/2007',
    legacyScheme: '2006',
    currentSalary: 43134,
    currentRank: 'Constable / Sergeant',
    dateRankAchieved: 'More than 5 years',
    dateOfLeaving: '',
  };

  created() {
    this.inputValues.status = this.inputResults.status;
    this.inputValues.dob = this.inputResults.dob;
    this.inputValues.gender = this.inputResults.gender;
    this.inputValues.dateJoinedService = this.inputResults.dateJoinedService;
    this.inputValues.legacyScheme = this.inputResults.legacyScheme;
    this.inputValues.currentSalary = this.inputResults.currentSalary;
    this.inputValues.currentRank = this.inputResults.currentRank;
    this.inputValues.dateRankAchieved = this.inputResults.dateRankAchieved;
    this.inputValues.dateOfLeaving = this.inputResults.dateOfLeaving;
  }

  get inputResults(): UserInputs {
    return this.$store.state.inputValues;
  }

  public showResults(): void {
    const resultsElement: HTMLDivElement = document.querySelector('.right-wrapper');

    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$v.$reset();
      this.inputValues.dob = moment(this.inputValues.dob, "D/M/YYYY").format("DD/MM/YYYY");
      this.inputValues.dateJoinedService = moment(this.inputValues.dateJoinedService, "D/M/YYYY").format("DD/MM/YYYY");
      this.inputValues.dateOfLeaving = moment(this.inputValues.dateOfLeaving, "D/M/YYYY").format("DD/MM/YYYY");
      this.inputValues.currentSalary = replacePoounds(this.inputValues.currentSalary);
      this.$store.commit("udateIsValidated", true);
      this.$store.commit("updateInputValues", this.inputValues);
      this.$emit("showResults");
    }

    resultsElement?.scrollIntoView({behavior: 'smooth'})
  }

  private setDirty(): void {
      this.$emit("hideRestuls");
  }

  @Validations()
  validations = {
    inputValues: {
      status: { required },
      dob: { required, isDate,
        isValidRange: function (val) {
            const dateval = - moment(val, "D/M/YYYY").diff(moment(), 'years', true);
            if (dateval < 75 && dateval > 16) return true;
            return false;
        },
      },
      gender: { required },
      dateJoinedService: { required, isDate,
        isValidRange: function (val) {
            if (this.inputValues.dob != null) {
              const dob = moment(this.inputValues.dob, 'D/M/YYYY');
              if (dob.isValid()) {
                const dateval = moment(val, "D/M/YYYY").diff(dob, 'years', true);
                if (dateval >= 16) return true;
                return false;
              } else {
                return true;
              }
            }
        },
      },
      legacyScheme: { required },
      currentSalary: { required, between: function (val) {
        const valR = replacePoounds(val);
        if(isNaN(valR)) return false;
        if (valR > 7500 && valR < 1000000) return true;
        return false;
      } },
      currentRank: { required },
      dateRankAchieved: { required },
      dateOfLeaving: {
        required: requiredIf(function () {
          return (
            this.inputValues.status == 'Deferred Pensioner' ||
            this.inputValues.status == 'Pensioner'
          );
        }),
        isNeededAndDate: function (val) {
          if (
            this.inputValues.status == '' ||
            this.inputValues.status == 'Active'
          )
            return true;
          const dateval = moment(val, 'D/M/YYYY', true);
          return dateval.isValid();
        },
        isValidRange: function (val) {
            if(this.inputValues.status == '' || this.inputValues.status == 'Active') return true;
            const dateval = - moment(val, "D/M/YYYY").diff(moment(), 'years', true);
            if (dateval < 20 && dateval > 0) return true;
            return false;
        },
      },
    },
  };
}
